@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  /* force background graphics without ask for user permissions */
  body {
    -webkit-print-color-adjust: exact !important;
  }
  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}
@layer utilities {
  @variants responsive {
    .hero-cards-dimensions {
      height: 375px;
      width: 100%;
    }
    .hero-cards-dimensions-xl {
      height: 385px;
      width: 100%;
    }
    .hero-cards-dimensions-3xl {
      height: 479px;
      width: 328px;
    }
    .dashboard-thumbnail-dimensions-xl {
      width: 100%;
      height: 100%;
    }
    .dashboard-thumbnail-dimensions-1620media {
      width: 100%;
      height: 100%;
    }
    .dashboard-thumbnail-dimensions-3xl {
      width: 100%;
      height: 100%;
    }
    .recommended-thumbnail-dimensions-lg {
      width: 250px;
      height: 100%;
    }
    .recommended-thumbnail-dimensions-1620media {
      width: 250px;
      height: 100%;
    }
    .playlist-thumbnail-dimensions-3xl {
      width: 343px;
      height: 100%;
    }
    .playlist-newthumbnail-dimensions-xl {
      width: 300px;
      height: 100%;
    }
    .playlist-newthumbnail-dimensions-3xl {
      width: 343px;
      height: 100%;
    }
    .background-dashboard-md {
      background-image: url('./assets/dashboard-imgs/elevo-bg.png');
      background-position-y: -279px;
      background-position-x: -90px;
      background-size: 2500px 664px;
      background-repeat: no-repeat;
    }
    .background-dashboard-xl {
      background-image: url('./assets/dashboard-imgs/elevo-bg@2x.png');
      background-size: 129% 150%;
      background-position-y: 111.5%;
      background-position-x: -134px;
      background-repeat: no-repeat;
    }
    .background-dashboard-3xl {
      background-image: url('./assets/dashboard-imgs/elevo-bg@2x.png');
      background-size: 129% 162%;
      background-position-y: 116.5%;
      background-position-x: -134px;
      background-repeat: no-repeat;
    }
    .background-video-details-3xl {
      background-image: url('./assets/dashboard-imgs/elevo-bg@2x.png');
      z-index: -1;
      background-size: 2801.6px 1596.9px;
      background-position: -817px -677px;
      background-repeat: no-repeat;
    }
    .background-exploreall-page-3xl {
      background-image: url('./assets/dashboard-imgs/elevo-bg@2x.png');
      z-index: -1;
      background-size: 2801.6px 1596.9px;
      background-position: -617px -677px;
      background-repeat: no-repeat;
    }

    .background-playlist-comming-soon-1 {
      background-image: url('./assets/playlist-imgs/Gradients_1.png');
      background-repeat: no-repeat;
      z-index: -1;
      background-size: contain;
      background-position: 700px 0px;
    }
    .background-playlist-comming-soon-2 {
      background-image: url('./assets/playlist-imgs/Gradients_2.png');
      background-repeat: no-repeat;
      z-index: -1;
      background-position: 21px 100px;
      background-size: contain;
    }
    .background-playlist-comming-soon-2-3xl {
      background-image: url('./assets/playlist-imgs/Gradients_2.png');
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;

      background-position: 21px -50px;
    }
    .background-playlist-comming-soon-3 {
      background-image: url('./assets/playlist-imgs/Elavo background image@2x 1.png');
      z-index: -1;
      background-size: cover;
      background-position: 0px -29.1px;
      background-repeat: no-repeat;
    }
    .width-3xl {
      width: 1001px;
    }
    /* aspect ratio grids from thumbnails */
    .thumbnails-grid {
      display: grid;
      grid-template-columns: repeat(1, minmax(160px, 1fr));
      grid-auto-rows: 1fr;
      grid-gap: 20px;
    }
    .thumbnails-grid-500 {
      display: grid;
      grid-template-columns: repeat(2, minmax(160px, 1fr));
      grid-auto-rows: 1fr;
      grid-gap: 20px;
    }
    .thumbnails-grid-lg {
      display: grid;
      grid-template-columns: repeat(3, minmax(160px, 1fr));
      grid-auto-rows: 1fr;
      grid-gap: 20px;
    }
    .thumbnails-grid-xl {
      display: grid;
      grid-template-columns: repeat(4, minmax(160px, 1fr));
      grid-auto-rows: 1fr;
      grid-gap: 20px;
    }
  }
}
.text-shadow {
  text-shadow: none;
  transition: text-shadow letter-spacing linear 0.3s;
}
.text-shadow:hover {
  text-shadow: 0.5px 0 0.5px white, -0.5px 0 0.5px white;
}
.input-field {
  @apply p-2 border border-gray-monson bg-white-alabaster rounded w-2/4 outline-none focus:ring focus:border-blue-300 mt-1;
}
.nav-toggle {
  transform: rotate(-90deg);
  transition: all 0.3s linear;
}
.nav-toggle-reverse {
  transform: rotate(0);
  transition: all 0.3s linear;
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #f1f5f8;
  z-index: 40;
  transform: translateX(-100%);
  transition: all 0.3s linear;
  opacity: 0;
}
.show-sidebar {
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}
.close-modal-btn {
  position: absolute;
  font-size: 3rem;
  border: none;
  top: 0;
  right: 0;
  margin: 15px;
  display: flex;
}
.hide-modal-btn {
  display: none;
}
.font-size-22px {
  font-size: 22px;
}
.header-height {
  height: 3.75rem;
}
.font1-35rem {
  font-size: 1.35rem;
}
.height-glass-icon {
  height: 1.31rem;
}
.width-glass-icon {
  width: 1.31rem;
}
/* menu header underline animation */
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: transform 0.25s ease-out;
  opacity: 0.3;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
}
/* intrinsec slick slider component styles */
.slick-prev:before,
.slick-next:before {
  color: #f5664f !important;
}

/** Carousel arrows **/
.react-multiple-carousel__arrow--left {
  top: 0;
  left: 0 !important;
  height: 100%;
  border-radius: 0 !important;
}

.react-multiple-carousel__arrow--right {
  top: 0;
  right: 0 !important;
  height: 100%;
  border-radius: 0 !important;
}
.p-top-20vh {
  padding-top: 20vh;
}

.width-34per {
  width: 34%;
}

@media only screen and (max-width: 1200px) {
  .background-playlist-comming-soon-1 {
    background-size: auto 100vh;
    background-position: 700px 0px;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  body {
    margin: 0 !important;
    color: #000;
    background-color: #fff;
  }
}
